import React from 'react';
import Content from "./components/Content";
import {Trans, useTranslation} from "react-i18next";


function Contact(props) {

    const {t} = useTranslation();

    return (
        <Content background="dimmed">
            <div id="contact" ref={props.menuref}><h1>{t('menu_title_4')}</h1></div>
            <div className="content" style={{width: "100%", marginTop: '50px'}}>
                <div className="content-row">
                    <div style={{width: "100%", padding: "0px", borderTop: '1px solid black', borderLeft: '1px solid black',borderRight: '1px solid black',
                    }}>
                        <div className="map" style={{width: "100%", height: "360px"}}>
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe width="100%" height="100%" id="gmap_canvas"
                                            src="https://maps.google.com/maps?q=katowice,opolska22&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            frameBorder="0" scrolling="no" marginHeight="0"
                                            marginWidth="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-row">
                    <div className="contact-row">
                        <div className="column-25">
                            <img src="elprimero.png" style={{opacity: 0.8}}/>
                        </div>
                        <div className="contact-address-row">
                            <div style={{
                                marginLeft: '50px'
                            }}>
                                <h2 style={{textAlign: "left"}}>{t('address_title')}</h2>
                                <p style={{textAlign: "left"}}>
                                    <Trans i18nKey="address_content"></Trans>
                                </p>
                            </div>
                            <div style={{
                                marginLeft: '50px'
                            }}>                                <h2 style={{textAlign: "left"}}>{t('phone_title')}</h2>
                                <p style={{textAlign: "left"}}>
                                    {t('phone_content')}
                                </p>
                            </div>
                            <div style={{
                                marginLeft: '50px'
                            }}>                            <h2 style={{textAlign: "left"}}>{t('email_title')}</h2>
                            <p style={{textAlign: "left"}}>
                                <a href={`mailto: ${t('email_content')}`}>{t('email_content')}</a>
                            </p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
</Content>
)
    ;
}

export default Contact;
