import React, {useEffect, useRef, useState} from 'react';
import Content from "./components/Content";
import {useTranslation} from "react-i18next";
import { IoVolumeMuteSharp,IoVolumeHighSharp,IoRefresh} from 'react-icons/io5';

function Ct4215(props) {

    const {i18n, t} = useTranslation();

    const videoRef = useRef();

    const [mute, setMute] = useState(true);

    const toggleMute = () => {
        setMute(prevState => !prevState);
    }

    const restartVideo = () => {
        videoRef.current.play();
    }

    return (
        <Content background="dimmed" width={props.fullWidth ? "full-width" : ""}
                 height="noheight">
            <div id="promo" ref={props.menuref}><h1>{t('promo_title')}</h1></div>
                <div className="content">
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <video controls controlsList="nodownload" ref={videoRef} src="laserdetection.mp4" autoPlay={true} muted={mute}  style={{ width: "100%", maxWidth: '720px', padding: '0%', marginTop: "50px"}}/>
                        </div>
                    </div>
                </div>
        </Content>
    );
}

export default Ct4215;
