import React from 'react';
import './MenuLink.css'
function MenuLink(props) {
    return (
        <div className={`menu-link ${props.active? 'active' : ''}`} onClick={props.onAction}>
            <a>{props.children}</a>
        </div>
    );
}

export default MenuLink;
