import './SentenceSlider.css'
import React, {useEffect, useState} from "react";

function SentenceSlider(props) {

    const [sentences, setSentences] = useState(props.sentences);

    const [old, setOld] = useState(0);
    const [current, setCurrent] = useState(0);
    const [nowy, setNowy] = useState(0);

    let interval;

    useEffect(() => {

        interval = setInterval(() => {
            setCurrent(prevState => {
                if(sentences && prevState>=sentences.length-1)
                    return 0;
                else
                    return prevState+1;
            });
        }, 4000);
        return () => clearInterval(interval);
    }, []);


    return (
       <div className="sentence-container">
           {sentences.map((s, index)=> <h1 key={index} className={`sentence ${index==current ? 'goingin' : 'hidden'}`} >{s}</h1>)}
       </div>
    );
}

export default SentenceSlider;
