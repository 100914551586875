import React from 'react';
import "./Content.css";

function Content(props) {
    return (
        <div className={`content-container ${props.withFeature} ${props.background} ${props.height} ${props.width} `}>
            {props.children}
        </div>
)
    ;
}

export default Content;
