import React from 'react';
import "./Footer.css";

function Footer(props) {

    return (
        <div className="footer-container">
            <span>Mariusz Wiśniowski - <a href="https://mariuszwisniowski.pl" target="_blank">mariuszwisniowski.pl</a></span>
        </div>
    );
}

export default Footer;
