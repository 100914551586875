import React from 'react';
import Content from "./components/Content";
import {Trans, useTranslation} from "react-i18next";

function Press(props) {

    const {t, i18n} = useTranslation();

    return (
        <Content background="dimmed" width={props.fullWidth ? "full-width" : ""}
                 height={props.withFeature ? "with-feature" : ""}>
            <div id="press" ref={props.menuref}>
                <br/>
                <h1>{t('menu_title_5')}</h1>
            </div>
            {i18n.language === "en" &&
                <div className="content press-content" style={{}}>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <h2>Laser spectroscopy microleak detection systems enhance aerosol production efficiency and
                                product safety</h2>
                        </div>
                    </div>
                    <div className="content-row" style={{marginBottom: "0px"}}>
                        <div className="column-33" style={{paddingRight: "0px"}}>
                            <h2 style={{
                                color: "white",
                                textAlign: 'right',
                                width: '100%',
                                marginTop: "10px"
                            }}>Real-world results showcase successful aerosol microleak detection system deployments
                                that ensure process safety, product quality, and regulatory compliance, while reducing
                                testing pain points and cost. By Graeme Walker & Jason Mitchell, Emerson</h2>
                        </div>
                        <div className="column-66">
                            <p className="two-columns" style={{textAlign: 'justify', width: '100%'}}>
                                Since the introduction of “bug bombs” in the 1940s, aerosols have come a long way,
                                experiencing a vast expansion of
                                applications, and a corresponding increase in manufacturing volume. According to a Grand
                                View Research study, the industry was valued at $78.7 billion (€70.7bn) globally in
                                2022, and it is expected to grow an average of 6.7% per year from 2023 to 2030.
                                To meet market demands and handle such volume, assembly lines must accommodate
                                unprecedented levels of package varieties, while ensuring quality, safety and regulatory
                                compliance. Leakage can occur in aerosol products due to defective components and
                                manufacturing issues, including split gaskets in valves, pinholes in cans, moulding
                                flash on valve components, clinch or crimp failures, and others.
                                Detecting and eliminating these sorts of defects before product reaches the market is
                                critical for upholding consumer safety and the manufacturer’s reputation, so companies
                                must closely monitor finished goods, and use findings to improve their manufacturing
                                processes. </p>
                        </div>
                    </div>
                    <div className="content-row" style={{paddingRight: "0px", paddingLeft: "0px", marginTop: "10px"}}>
                        <div className="column-33" style={{paddingRight: "0px", paddingLeft: "0px"}}>
                            <img src="article_1.jpg" style={{width: "100%"}}/>
                        </div>
                        <div className="column-66">
                            <p style={{textAlign: 'justify', marginTop: "0px"}}><h3 style={{marginTop: "0px"}}>Microleak
                                detection methodology</h3>
                                Historically, hot water baths were used to test for aerosol can deformations and leaks
                                that could lead to dangerous bursts. In the early days, operators on the line observed
                                each can passing through the bath, looking for bubbles that indicated leakage, but as
                                line speeds increased, manual detection became unfeasible.
                                Even with automated monitoring systems in place, water baths have several drawbacks,
                                requiring:
                                <ul>
                                    <li>high expenses to buy and install, about $250,000+ (€224.8k+).</li>
                                    <li>a large footprint in space-constrained facilities.</li>
                                    <li>significant energy investment to maintain continuous water heating above
                                        50°C/122°F.
                                    </li>
                                    <li>frequent maintenance and expensive chemicals to avoid corrosion and keep the
                                        bath clean.
                                    </li>
                                </ul>
                                An alternative methodology is preferred, but it must still meet an equivalent level of
                                safety as supplied by water baths. This requirement is critical because failure to
                                identify leaking aerosol cans can result in the release of
                                flammable or explosive gases or liquids, risking product recalls, brand reputation
                                damage and, worst of all, safety incidents.
                                The following sections will explore real results achieved by aerosol product
                                manufacturers that deployed a modern
                                alternative to ensure quality and safety, while simultaneously reducing testing time and
                                cost.

                                Lubricant manufacturer moves packaging and testing in-house
                                A lubricant manufacturer designs, formulates, tests, blends, packages, and distributes
                                synthetic and semi-synthetic solutions in a variety
                            </p>
                        </div>
                    </div>
                    <div className="content-row"
                         style={{paddingRight: "0px", paddingLeft: "0px", marginTop: "10px", alignItems: "center"}}>
                        <div className="column-66" style={{paddingLeft: "0px"}}>
                            <img src="ct2211.jpg" style={{width: "100%", marginBottom: "10px", marginTop: "10px"}}/>
                        </div>
                        <div className="content-column column-33">
                            <h1 style={{textAlign: "left"}}>This solution empowered the company to
                                meet aerosol quality regulations, while ensuring the safety and integrity of its
                                products
                            </h1>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="content-column column-100">
                            <p className="three-columns" style={{textAlign: 'justify', width: '100%'}}>
                                of industrial and automotive markets worldwide. The manufacturer previously partnered
                                with an aerosol filling company to package its R134A refrigerant propellant, but high
                                defect rates were resulting in 2.5 to 5% product loss. This prompted the manufacturer to
                                move its filling process in-house, which required design and procurement of appropriate
                                packaging equipment.
                                With a limited production floor footprint, the company was particularly motivated to
                                determine an approved alternative to hot water baths for microleak detection and
                                pressure testing. To meet requirements, the manufacturer purchased Emerson’s Rosemount™
                                Aerosol Microleak Detection System, a laser spectroscopy system based on quantum cascade
                                laser (QCL) technology (Figure 1).

                                This system is capable of detecting, identifying, and rejecting faulty cans at very fast
                                line speeds, 300 cans per minute in the case of the refrigerant packaging system. It
                                does this by drawing in the air around an aerosol can while it passes through the sample
                                arch, directing the air through a measurement cell, and focusing
                                a laser beam through the sample gas, which is reflected to a detector (Figure 2).
                                Variations in light intensity are measured, and the leak rate is then calculated using
                                infrared spectroscopy. If the leak rate is above specification, the product is
                                automatically rejected.
                                This solution empowered the company to meet aerosol quality regulations, while ensuring
                                the safety and integrity of its
                                products, without the ongoing space, cost and maintenance drawbacks of water bath
                                systems

                                Additionally, the solution’s onboard diagnostic and data-producing capabilities enabled
                                the manufacturer to optimise its packaging process control with actionable insights,
                                along with visibility into batch quantities and rejection rates. This helped the company
                                increase its use of automation, reduce waste and improve environmental stewardship.
                                As a result of the in-house packaging and improved testing system, the company saved an
                                estimated $200,000 (€179.9k) in capital costs as compared to a water bath system, with
                                an additional $35,000 (€31.4k) in energy, consumables, and maintenance
                                cost savings each year. These numbers do not even factor valuable space gains on the
                                plant floor into consideration, which provide additional benefits.<br/><br/>
                                Contracted filler increases line efficiency and testing integrity
                                Some companies in the aerosol industry are not responsible for manufacturing products,
                                but rather specialise in filling and packaging
                            </p>
                        </div>
                    </div>
                    <div className="content-row" style={{
                        width: '100%',
                        textAlign: "left",
                        border: "1px solid #343434",
                        padding: "10px",
                        backgroundColor: "rgba(140,140,140,0.1)",
                        marginTop: "20px"
                    }}>
                        <div className="column-66" style={{paddingLeft: "0px"}}>
                            <img src="article_2.jpg" style={{width: "100%"}}/>
                        </div>
                        <div className="column-33">
                            <ol style={{textAlign: 'left', padding: '15px'}}>
                                <li style={{marginBottom: '5px'}}>Air extraction arch: draws the air from around the
                                    aerosol can into the sample handling system
                                </li>
                                <li style={{marginBottom: '5px'}}>Air filter: used for the removal of air particles and
                                    leaked contents of the aerosol cans
                                </li>
                                <li style={{marginBottom: '5px'}}>Sample cell: laser light is directed through the air
                                    extracted from around the cans and back into the sensor head.
                                </li>
                                <li style={{marginBottom: '5px'}}>Sensor head: contains the lasers and laser light
                                    detector. It is rated ATEX, IECEx and UKEx Category 3 for use in Zone 2, and for use
                                    in Class 1, Division 2, Groups A, B, C, & D, T6.
                                </li>
                                <li style={{marginBottom: '5px'}}>Conveyor belt: used to transport the aerosol cans</li>
                                <li style={{marginBottom: '5px'}}>Automated can rejection/pusher mechanism</li>
                                <li style={{marginBottom: '5px'}}>Rejection bin</li>
                            </ol>
                        </div>
                    </div>
                    <div className="content-row">
                        <div style={{width: '100%'}}>
                            <table style={{
                                width: '100%',
                                textAlign: "left",
                                border: "1px solid #343434",
                                padding: "10px",
                                backgroundColor: "rgba(140,140,140,0.1)",
                                marginTop: "20px"
                            }}>
                                <tr>
                                    <th style={{width: '33%'}}>Configuration</th>
                                    <th style={{width: '33%'}}>Single Laser Aerosol Microleak Detection System</th>
                                    <th style={{width: '33%'}}>Multi-Laser Aerosol Microleak Detection System</th>
                                </tr>
                                <tr style={{
                                    width: '100%',
                                    textAlign: "center",
                                    background: "rgba(255,255,255,0.7)",
                                    fontWeight: "bold",
                                    color: "black"
                                }}>
                                    <td colSpan={3}>Sensitivity</td>
                                </tr>
                                <tr>
                                    <td>Post Water Bath: 8 x 10-3 mBarL<sup>-1</sup></td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Water Bath Alternative: 2 x 10-3 mbarLs<sup>-1</sup></td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr style={{
                                    width: '100%',
                                    textAlign: "center",
                                    background: "rgba(255,255,255,0.7)",
                                    fontWeight: "bold",
                                    color: "black"
                                }}>
                                    <td colSpan={3}>Propellant</td>
                                </tr>
                                <tr>
                                    <td>Propane</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Butane</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>DME</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>134a</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>CO<sub>2</sub></td>
                                    <td>Post water bath only</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>HFO</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>152a</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>N<sub>2</sub>O</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </table>
                            <p style={{fontSize: "12px"}}>The Rosemount CT2211 Aerosol Microleak Detection System is
                                configurable with a single or multi-laser sensor head, adaptable to user needs</p>

                        </div>
                    </div>
                    <div className="content-row">
                        <div className="column-100">
                            <p className="three-columns" style={{textAlign: 'justify', width: '100%'}}>
                                other companies’ products into consumable forms for the market. As with companies that
                                package their own products, contract fillers are subject to the same stringent safety
                                and quality requirements to minimise product leakage and flammability risks.
                                Brand owners often mandate the use of a microleak detection system by their filling
                                contractors. These contractors traditionally
                                used conventional hot water baths to identify faulty cans and seals, but modern
                                laser-based alternative analysis systems are empowering fillers to detect smaller leaks
                                with higher accuracy at faster line speeds. Additionally, these systems have lower
                                maintenance overhead because they have fewer moving parts and do not require
                                calibration.
                                One such contracted filler installed a Rosemount Aerosol Microleak Detection System in
                                its line to conduct quantifiable test regimes for microleaks, initially downstream of
                                its water baths, but eventually replacing them. This ensured the company was fully
                                compliant with pressure and leak testing requirements and regulations, providing quality
                                assurance

                                over a range of product lines for various manufacturers at line speeds up to 220 cans
                                per minute.
                                The new system is ATEX zone 2 and Class 1 Division 2 certified for use in hazardous
                                environments. The system is integrated into the company’s non-destructive product
                                testing regime, and it is capable of automatically rejecting defective products from the
                                conveyor belt used to transport cans in and out of the microleak detection system. These
                                capabilities enabled the contractor to take on more orders over a multitude of
                                propellants (Table 1), meeting higher production demands without compromising quality or
                                safety.
                                Additionally, the contractor used batch insights provided by the microleak detection
                                system to collect and analyse performance data previously unavailable with its
                                traditional water bath inspection systems. This information helped it optimise filling
                                and packaging processes by identifying production issues that could lead to faulty or
                                contaminated cans, and it helped the contractor shift from reactive to proactive
                                maintenance practices.

                                Reduce waste with laser-based leak detection systems
                                Modern microleak detection systems are not merely quality gates, they can additionally
                                serve as a powerful process performance indicator. For this and other reasons, the road
                                to return on investment is short when leveraging these systems to identify and address
                                developing issues before they turn into mass-scale systemic failures and wasted product.
                                With a wide range of applications, QCL spectroscopy microleak detection systems are
                                empowering aerosol manufacturers to produce profitably, conduct business reputably,
                                operate sustainably, and adhere to the highest safety and quality standards.

                                All figures courtesy of Emerson Measurement Solutions
                            </p>
                        </div>
                    </div>


                </div>}
            {i18n.language === "en" &&
                <div className="content press-content" style={{}}>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <h2>Laser-based Microleak Detection Improves Aerosol Production Efficiency and Product
                                Safety</h2>
                            <p>by Graeme Walker, Jason Mitchell | Oct 3, 2023 | Analytical, Measurement
                                Instrumentation</p>
                        </div>
                    </div>
                    <div className="content-row" style={{marginBottom: "0px"}}>
                        <div>
                            <p style={{textAlign: 'justify', width: '100%'}}>
                                The aerosol manufacturing industry has come a long way since the introduction of “bug
                                bombs” during WWII. To meet today’s high demand for aerosol products, assembly lines
                                must produce copious volumes in a wide variety of packaging, while upholding high
                                standards of safety and quality.
                                Any product defects—including split gaskets in valves, pinholes in cans, molding flash
                                on valve components, and clinch or crimp failures, and others—must be detected and
                                immediately eliminated to prevent leakage of potentially flammable or otherwise
                                dangerous substances.
                                Contrasting with hot water bath systems, our article in the July/August 2023 edition of
                                World Aerosols, titled Laser Spectroscopy Microleak Detection Systems Enhance Aerosol
                                Production Efficiency and Product Safety, showcases the benefits of laser-based
                                microleak detection systems.</p>
                            <h3 style={{textAlign: 'left'}}>Detecting Microleaks</h3>
                            <p>Early leak detection systems consisted of operators manually observing aerosol cans
                                immersed in hot water baths, which they meticulously examined for the presence of
                                bubbles, indicating leakage. As industrialization progressed and production line speeds
                                continuously increased over time, automated water baths began to replace human
                                observers, but issues remained.

                                However, even with automated monitoring systems in place, water baths have several
                                drawbacks, requiring:

                                <ul>high expenses to buy and install, about $250,000+ USD.
                                    <li>a large footprint in space-constrained facilities.</li>
                                    <li>significant energy investment to maintain continuous water heating above
                                        50°C/122°F.
                                    </li>
                                    <li>frequent maintenance and expensive chemicals to avoid corrosion and keep the
                                        bath clean.
                                    </li>
                                </ul>
                                With all these disadvantages, an effective alternative providing higher throughput with
                                improved leak detection and safety is an attractive prospect. Modern quantum cascade
                                laser-based systems address these and other issues, as you will see in the following
                                applications.
                            </p>
                            <h3 style={{textAlign: 'left'}}>Lubricant manufacturer brings aerosol filling in-house, and
                                contract filler increases production rate</h3>
                            <p>
                                A lubricant manufacturer previously partnered with an aerosol company to package its
                                products, but high defect rates resulted in 2.5 to 5% product loss, prompting the
                                manufacturer to move its filling process in-house.
                                <br/>
                                <br/>
                                With limited space and a desire for superior performance, the company opted for
                                Emerson’s laser-based Rosemount Aerosol Microleak Detection System, rather than a hot
                                water bath.
                                <br/>
                                <br/>
                                This system is capable of detecting, identifying, and rejecting faulty cans at very fast
                                line speeds, 300 cans per minute in the case of the refrigerant packaging system. It
                                does this by drawing in the air around an aerosol can while it passes through the sample
                                arch, directing the air through a measurement cell, and focusing a laser beam through
                                the sample gas, which is reflected to a detector. Variations in light intensity are
                                measured, and the leak rate is then calculated using infrared spectroscopy. If the leak
                                rate is above specification, the product is automatically rejected.
                            </p>
                            <p>
                                The system meets stringent safety and quality regulations without the drawbacks of water bath systems, while additionally providing diagnostic insights that enable the manufacturer to optimize its packaging process. Installing the system cost $200,000 USD less than a comparable water bath solution, and the company saves an estimated $35,000 each year in energy, consumables, and maintenance costs.
                                For contract fillers that specialize in filling and packaging other companies’ products into consumable aerosol forms, moving at high speeds while upholding safety and quality requirements is critical to success. One such filler installed a Rosemount Aerosol Microleak Detection System, which eventually replaced their water baths, to provide quality assurance for a range of product lines at a speed of up to 220 cans per minute.
                            </p>
                            <p>
                                The contract filler collects and analyzes batch performance data provided by the system to optimize production, identify problems, and implement proactive maintenance practices. These advantages empower the contractor to take on higher production demands, without compromising product quality or human safety.
                            </p>
                            <h3 style={{textAlign: 'left'}}>Laser-based systems improve efficiency</h3>
                            <p>
                                Modern microleak detection systems are more than just quality gates as they enable aerosol packagers to monitor production performance and identify issues as they develop, significantly reducing faulty products or upstream machine failure. These capabilities help grow profits, boost company reputation, and elevate environmental sustainability, all while maintaining quality and safety requirements during high-speed production.
                            </p>
                        </div>
                    </div>
                </div>}
        </Content>
    );
}

export default Press;
