import React from 'react';

import './LoadingSpinner.css';

const LoadingSpinner = props => {
    return (
        <div className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
            <img src="./logo_white.png"/>
        </div>
    );
};

export default LoadingSpinner;
