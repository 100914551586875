import React, {useState} from 'react';
import styles from "./Header.module.css";
import {useTranslation} from "react-i18next";
import MenuLink from "./components/MenuLink";

function Header(props) {

    const {t} = useTranslation();

    const [address, setAddress] = useState('');

    const goToAddress = (address) => {
        console.log("Going to" + address);
        setAddress(address);
        window.location.href = address;
    }

    return (
        <div className={styles.headerContainer} id="top">
            <nav className={`${styles.navContainer} ${props.showMenu ? styles.showMenu : null}`}>
                <div className={styles.navInnerContainer}>
                 <div className={styles.languageButtons}>
                    <a onClick={props.changeLang.bind(this, "en")} className={`${props.language==='en' ? 'selected' : ''}`}>EN</a>
                    <a onClick={props.changeLang.bind(this, "pl")} className={`${props.language==='pl' ? 'selected' : ''}`}>PL</a>
                    </div>
                <div>
                    <MenuLink onAction={props.goTo.bind(this, "top")} active={props.selectedItem==0}>HOME</MenuLink>
                    <MenuLink onAction={props.goTo.bind(this, "about")} active={props.selectedItem==1}>{t('menu_title_1')}</MenuLink>
                    <MenuLink onAction={props.goTo.bind(this, "press")} active={props.selectedItem==5}>{t('menu_title_5')}</MenuLink>
                    <MenuLink onAction={props.goTo.bind(this, "ct2211")} active={props.selectedItem==3}>{t('menu_title_3')}</MenuLink>
                    <MenuLink onAction={props.goTo.bind(this, "ct4215")} active={props.selectedItem==2}>{t('menu_title_2')}</MenuLink>
                    <MenuLink onAction={props.goTo.bind(this, "contact")} active={props.selectedItem==4}>{t('menu_title_4')}</MenuLink>
                </div>
                <div className="title-container">
                    <a
                        className={styles.menuLogo}
                        href="http://elprimero.eu"
                    >
                        <img
                            src="./elprimero.png"
                            alt="elprimero Logo"
                            height={38}
                        />
                    </a>
                </div>
                </div>
            </nav>
            <main className={`${styles.main}`}>
                <div className={styles.description}>
                        <div className={styles.languageButtons}>
                            <a onClick={props.changeLang.bind(this, "en")} className={`${props.language==='en' ? 'selected' : ''}`}>EN</a>
                            <a onClick={props.changeLang.bind(this, "pl")} className={`${props.language==='pl' ? 'selected' : ''}`}>PL</a>
                        </div>
                    <div>
                        <a
                            className={styles.card}
                            href="http://elprimero.eu"
                        >
                            <img
                                src="./elprimero.png"
                                alt="elprimero Logo"
                                width={100}
                            />
                        </a>
                    </div>
                </div>

                <div className={styles.center}>
                    <h1>{t('title')}</h1>
                    <h3 className={styles.promoBtn} onClick={props.goTo.bind(this, "promo")}>{t('menu_promo_title')}</h3>
                </div>

                <div className={styles.grid}>
                    <a
                        className={styles.card}
                        onClick={props.goTo.bind(this, "about")}
                    >
                        <h2>
                            {t('menu_title_1')} <span>-&gt;</span>
                        </h2>
                        <p>
                            {t('menu_subtitle_1')}
                        </p>
                    </a>
                    <a
                        className={styles.card}
                        onClick={props.goTo.bind(this, "press")}
                    >
                        <h2>
                            {t('menu_title_5')} <span>-&gt;</span>
                        </h2>
                        <p>
                            {t('menu_subtitle_5')}
                        </p>
                    </a>
                    <a
                        className={styles.card}
                        onClick={props.goTo.bind(this, "ct2211")}
                    >
                        <h2>
                            {t('menu_title_3')}
                            <span>-&gt;</span>
                        </h2>
                        <p>
                            {t('menu_subtitle_3')}
                        </p>
                    </a>

                    <a
                        className={styles.card}
                        onClick={props.goTo.bind(this, "ct4215")}
                    >
                        <h2>
                            {t('menu_title_2')}<span>-&gt;</span>
                        </h2>
                        <p>
                            {t('menu_subtitle_2')}
                        </p>
                    </a>


                    <a
                        className={styles.card}
                        onClick={props.goTo.bind(this, "contact")}
                    >
                        <h2>
                            {t('menu_title_4')} <span>-&gt;</span>
                        </h2>
                        <p>
                            {t('menu_subtitle_4')}                        </p>
                    </a>
                </div>
            </main>
        </div>
    );
}

export default Header;
