import React, {useEffect} from 'react';
import Content from "./components/Content";
import {useTranslation} from "react-i18next";

function Ct4215(props) {

    const {i18n, t} = useTranslation();

    return (
        <Content background="dimmed" width={props.fullWidth ? "full-width" : ""}
                 height={props.withFeature ? "with-feature" : ""}>
            <div id="ct4215" ref={props.menuref}>
                <br/>
                <h1>{t('menu_title_2')}</h1>
            </div>
            {i18n.language === "pl" &&
                <div className="content">
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <img src="ct4215.png" style={{width: '100%', padding: '10%'}}/>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'justify', width: '100%'}}>Rosemount™ CT4215 - System wykrywania
                                nieszczelności opakowań</h2>

                            <p style={{textAlign: 'justify'}}>Rosemount CT4215 to system liniowy, który dokładnie
                                testuje
                                100% opakowań z produktami spożywczymi i napojami przechodzącymi przez linie produkcyjne,
                                w celu osiągnięcia 100% jakości produkcji.</p>

                            <p style={{textAlign: 'justify'}}>System sprawdza/testuje/kontroluje do 200 opakowań na minutę, wykrywa wyciek śladowych ilości gazu z wadliwych opakowań i
                                natychmiast je odrzuca/eliminuje bez zakłócania/przerywania ciągu produkcji. System wykorzystuje
                                opatentowaną technologię Quantum Cascade Laser (QCL) i jest niewielkich
                                rozmiarów, łatwy w instalacji i konfiguracji oraz zapewnia niskie koszty utrzymania
                                przez cały okres eksploatacji.</p>

                            <h2 style={{textAlign: 'left', width: '100%'}}>Najważniejsze cechy charakteryzujące:</h2>

                            <ul style={{textAlign: 'justify'}}>
                                <li>100% wszystkich produktów na linii produkcyjnej jest testowanych w czasie
                                    rzeczywistym, w celu sprawdzenia szczelności opakowań,
                                </li>
                                <li>Sprawdza/testuje do 200 opakowań na minutę, zapewniając wysoką wydajność,</li>
                                <li>W pełni zautomatyzowane, szybkie wykrywanie i odrzucanie/eliminowanie wadliwych opakowań bez
                                    ingerencji w cykl produkcyjny skraca przestoje,
                                </li>
                                <li>System Inline został zaprojektowany z myślą o niewielkich wymiarach urządzenia, w celu łatwej integracji/montażu w linii produkcyjnej,
                                </li>
                                <li>Łatwy w instalacji lub modernizacji na dowolnej linii produkcyjnej w celu
                                    zminimalizowania przestojów i zakłóceń,
                                </li>
                                <li>Opatentowana technologia QCL wykrywa nieszczelności z czułością mniejszą niż 0,3 mm,
                                    zapewniając
                                    niezawodną dokładność,
                                </li>
                                <li>Przyjazne dla użytkownika oprogramowanie oferuje pełne możliwości rejestrowania
                                    danych,
                                    które monitorują dane produkcyjne w mgnieniu oka,
                                </li>
                                <li>Elastyczna modułowa konstrukcja umożliwiająca rozbudowę systemu o dodatkowe lasery lub głowice
                                    testujące w celu zwiększenia możliwości systemu,
                                </li>
                                <li>Alarmy systemowe szybko identyfikują nieszczelne opakowania i rozwiązują problemy w
                                    celu
                                    maksymalnej eliminacji ilości wadliwych opakowań w gotowej produkcji,
                                </li>
                                <li>System generuje niskie koszty konserwacji i obsługi przez cały okres eksploatacji.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'left', width: '100%'}}>Parametry produkcyjne:</h2>
                            <p style={{textAlign: 'left', width: '100%'}}>Wykrywane gazy:
                                CO2, N2O, CO, C2H6O<br/>
                                Czułość:
                                mniejsza niż 0.3 mm średnicy<br/>
                                Laser:
                                Klasa 1<br/>
                                Zakres temperatury:
                                -5 °C do 30 °C<br/>
                                Zakres wilgotności:
                                mniejszy niż 95%<br/>
                                Rodzaje opakowań:
                                Tace, woreczki, torby, beczki, puszki na mięso, nabiał, warzywa, chleb, makaron i napoje<br/>
                                Klasa szczelności:
                                IP65<br/><br/>
                                Prąd:
                                240 VAC 50 Hz<br/>
                                Zasilanie sprężonym powietrzem:
                                25 l/min, 8-10 bar, (115 do 145 PSI)<br/><br/>
                                Wymiary:
                                703 x 732 x 1852 mm<br/>
                                Waga:
                                do 20 kg<br/>
                            </p>
                        </div>
                    </div>
                    <div className="content-row">
                        <div style={{width: '100%'}}>

                        </div>
                    </div>
                </div>}
            {i18n.language === "en" &&
                <div className="content">
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <img src="ct4215.png" style={{width: '100%', padding: '10%'}}/>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'justify', width: '100%'}}>Rosemount™ CT4215 - Packaging leak detection system</h2>

                            <p style={{textAlign: 'justify'}}>The Rosemount CT4215 is an inline system that accurately
                                tests 100% of food and beverage product packages going through the production lines to
                                ensure seal integrity. This system measures up to 200 packs per minute, detects trace
                                gases from defective packs, and instantly rejects them without disrupting production.
                                Engineered with patented Quantum Cascade Laser (QCL) technology and designed with a
                                small footprint, it is easy to install and configure, and offers lifetime low
                                maintenance costs.</p>

                            <h2 style={{textAlign: 'left', width: '100%'}}>Features:</h2>

                            <ul style={{textAlign: 'justify'}}>
                                <li>100% of all products on the production line are tested in real time to assure seal
                                    integrity
                                </li>
                                <li>Measures up to 200 packs per minute for fast performance</li>
                                <li>Fully-automated, high-speed detection and rejection of defective products without
                                    line interference reduces downtime
                                </li>
                                <li>Inline system is designed with a small product footprint to easily integrate into
                                    operations
                                </li>
                                <li>Easy-to-install or retrofit onto any production line for minimized downtime and
                                    disruption
                                </li>
                                <li>Patented QCL technology detects leaks at a sensitivity of less than 0.3 mm for
                                    reliable accuracy
                                </li>
                                <li>User-friendly software features full data logging capability that monitors
                                    production data at-a-glance
                                </li>
                                <li>Flexible modular design can be upgraded with additional lasers or sample heads for
                                    increased system capabilities
                                </li>
                                <li>System alarms quickly identify leaking packages and resolve issues to reduce wasted
                                    packaging
                                </li>
                                <li>System engineered to ensure lifetime low maintenance costs for a cost-efficient
                                    solution
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'left', width: '100%'}}>Specifications:</h2>
                            <p style={{textAlign: 'left', width: '100%', paddingLeft: '20px'}}>
                                <strong>Sensitivity:</strong> less than 0.3 mm diameter<br/><br/>
                                <strong>Dimensions:</strong> Unit Dimensions: 28 x 29 x 73 in. (703 x 732 x 1852 mm)<br/><br/>
                                <strong>Weight:</strong> Unit Weight: 285 lb (129 kg)<br/><br/>
                                <strong>Laser Classification:</strong> Class I<br/><br/>
                                <strong>Detected Gas:</strong> CO2, N2O, CO, C2H6O<br/><br/>
                                <strong>Temperature Range:</strong> 23 to 86 °F (-5 to 30 °C)<br/><br/>
                                <strong>Humidity Range:</strong> less than 95%<br/><br/>
                                <strong>Power Supply:</strong> 120 Vac 60 Hz / 240 Vac 50 Hz<br/><br/>
                                <strong>Air Supply:</strong> Compressed Air Supply: 25 L/min, 8-10 bar, (115 to 145 PSI)<br/><br/>
                                <strong>Package Types:</strong> Trays, pouches, bags, kegs, cans for meat, dairy,
                                vegetables, bread, pasta, and beverages
                            </p>
                        </div>
                    </div>
                    <div className="content-row">
                        <div style={{width: '100%'}}>

                        </div>
                    </div>
                </div>}
        </Content>
    );
}

export default Ct4215;
