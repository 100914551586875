import React from 'react';
import Content from "./components/Content";
import {Trans, useTranslation} from "react-i18next";
import Featured from "./components/Featured";

function Ct2211(props) {

    const {t, i18n} = useTranslation();

    return (
        <Content background="dimmed" width={props.fullWidth ? "full-width" : ""}
                 height={props.withFeature ? "with-feature" : ""}>
            <div id="ct2211" ref={props.menuref}>
                <br/>
                <h1>{t('menu_title_3')}</h1>
            </div>
            {i18n.language === "pl" &&
                <div className="content">
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <img src="ct2211.jpg" style={{width: '100%', padding: '10%'}}/>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'justify', width: '100%'}}>Rosemount™ CT2211 - System wykrywania mikroprzecieków w gotowych produktach aerozolowych</h2>

                            <p style={{textAlign: 'justify'}}>System wykrywania mikroprzecieków w gotowych wyrobach aerozolowych Rosemount
                                CT2211 to zautomatyzowany, liniowy tester szczelności, który wykorzystuje technologię
                                Quantum Cascade Laser (QCL) do wykrywania i odrzucania nieszczelnych pojemników aerozolowych
                                w czasie rzeczywistym bez zakłócania pracy linii produkcyjnej. Certyfikowany do użytku
                                jako alternatywa dla kąpieli wodnej, Rosemount CT2211 poprawia produktywność i
                                wydajność, zapewniając stuprocentowe testy szczelności z prędkością do 600 pojemników na
                                minutę.
                                System jest w pełni zgodny z przepisami i normami branżowymi, w tym UN, ADR, DOT, FEA i
                                BAMA i zapewnia wysoce selektywne i czułe wykrywanie szerokiej gamy propelentów,
                                niezależnie od rozmiaru, kształtu lub materiału pojemnika aerozolowego.</p>

                            <p style={{textAlign: 'justify'}}>System sprawdza/testuje/kontroluje do 200 opakowań na minutę, wykrywa wyciek
                                śladowych ilości gazu z wadliwych opakowań i
                                natychmiast je odrzuca/eliminuje bez zakłócania/przerywania cyklu produkcji. System wykorzystuje
                                opatentowaną technologię Quantum Cascade Laser (QCL) i jest niewielkich
                                rozmiarów, łatwy w instalacji i konfiguracji oraz zapewnia niskie koszty
                                utrzymania
                                przez cały okres eksploatacji.</p>

                            <h2 style={{textAlign: 'left', width: '100%'}}>Najważniejsze cechy charakteryzujące:</h2>

                            <ul style={{textAlign: 'justify'}}>
                                <li>Zapewnia zwiększenie wydajności dzięki w pełni zautomatyzowanemu, wbudowanemu monitorowaniu i
                                    wykrywaniu wycieków produktu w czasie rzeczywistym bez zatrzymywania cyklu produkcyjnego
                                </li>
                                <li>Zapewnia jakość i zapobiega wycofaniu produktu dzięki stuprocentowej kontroli każdego
                                    pojedynczego pojemnika aerozolowego, natychmiastowemu czasowi reakcji i usuwaniu z linii produkcyjnej tylko wadliwych gotowych produktów
                                </li>
                                <li>Niweluje wąskie gardła produkcyjne dzięki szybkiemu działaniu i bardzo
                                    czułemu wykrywaniu wycieku szerokiej gamy śladowych ilości propelentów z prędkością do 600 pojemników/opakowań na minutę
                                </li>
                                <li>Zapewnia zgodność z owowiązującymi przepisami dzięki systemowi certyfikowanemu do użytku jako
                                    alternatywa dla testu w kąpieli wodnej
                                </li>
                                <li>Brak ruchomych części oraz konieczności kalibracji ułatwia konserwację: Łatwa konserwacja i utrzymanie - nie ma części ruchomych, nie wymaga kalibracji
                                </li>
                                <li>Przejście z reaktywnych do proaktywnych praktyk czynności obsługowych dzięki ciągłej
                                    diagnostyce stanu pracy
                                </li>
                                <li>Intuicyjny interfejs operatora oraz wbudowane narzędzia analityczne zapewniają
                                    praktyczny wgląd w dane dotyczące ilości jak i współczynniki eliminacji wadliwych opakowań pozwalając na
                                    optymalizację kontroli procesu
                                </li>
                                <li>ATEX zone 2 and Class 1 Division 2 – Certyfikacja do użytku w niebezpiecznych
                                    środowiskach oraz w środowiskach z gazami wybuchowymi
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'left', width: '100%'}}>Parametry produkcyjne:</h2>
                            <p style={{textAlign: 'left', width: '100%'}}>Wykrywane gazy:
                                Propan, Butan, LPG, N2O, CO2, DME, R1234ze, R134a, R227 – pozostałe na zamówienie
                                <br/>
                                Czułość:
                                Po kąpieli wodnej: 8 x 10-3 mBar l/s-1<br/>Alternatywa kąpieli wodnej: 2 x 10-3mBar
                                l/s-1<br/>
                                Laser:
                                Klasa 1<br/>
                                Zastosowania:
                                Propelenty w produktach spożywczych, medycznych, domowych, higieny osobistej,
                                motoryzacyjnych i przemysłowych<br/>
                                Klasa szczelności:
                                IP65<br/><br/>
                                Class 1 Division 2: Grupy A, B, C, D, T6 (Tamb +10 to +40 °C)<br/>
                                ATEX: Ex II 3G Ex nR II T6 (10 °C ≤ Tamb ≤ 40 °C)<br/><br/>
                                Wymiary:<br/>
                                Low Speed Console (unmounted):
                                38 cm H x 61 cm W x 22 cm D (15 in. H x 24 in. W x 9 in. D)<br/>
                                Single Propellant Sensor:
                                182 cm H x 33 cm W x 33 cm D (72 in. H x 13 in. W x 13 in. D)<br/>
                                Enclosure:
                                272 cm H x 54 cm W x 182 cm D (107 in. H x 21 in. W x 72 in. D)<br/>
                                High Speed Console:
                                120 cm H x 60 cm W x 50 cm D (47 in. H x 24 in. W x 22 in. D)<br/>
                                Multi-Propellant Sensor:
                                60 cm H x 33 cm W x 33 cm D (24 in. H x 13 in. W x 13 in. D)<br/>
                                Waga Console
                                do 70 kg<br/>
                                Waga Sensor:
                                do 20 kg<br/>

                            </p>
                        </div>
                    </div>
                    <div className="content-row">
                        <div style={{width: '100%'}}>

                        </div>
                    </div>
                </div>}
            {i18n.language === "en" &&
                <div className="content">
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                            <img src="ct2211.jpg" style={{width: '100%', padding: '10%'}}/>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'justify', width: '100%'}}>Rosemount™ CT2211 - Aerosol micro-leak detection system</h2>

                            <p style={{textAlign: 'justify'}}>The Rosemount CT2211 Aerosol Microleak Detection System is
                                an automated, inline leak tester that uses Quantum Cascade Laser (QCL) technology to
                                detect and reject leaking aerosol cans in real time without disrupting the production
                                line. Certified for use as a water bath alternative, the Rosemount CT2211 improves
                                productivity and efficiency by providing 100% aerosol can leak testing at speeds of up
                                to 600 cans per minute. The system is fully compliant with industry regulations and
                                standards, including UN, ADR, DOT, FEA and BAMA and provides highly selective and
                                sensitive detection of a wide variety of propellants regardless of can size, shape or
                                materials.</p>

                            <h2 style={{textAlign: 'left', width: '100%'}}>Features:</h2>

                            <ul style={{textAlign: 'justify'}}>
                                <li>Improve productivity with fully automated, inline monitoring and detection of
                                    aerosol can leaks in real-time without stopping the production line
                                </li>
                                <li>Ensure quality and prevent product recalls with 100 % inspection of each individual
                                    can, instant response time and removal of only non-conforming product
                                </li>
                                <li>Avoid a production bottleneck with high-speed operation and highly sensitive
                                    detection of a wide range of propellants at up to 600 cans per minute
                                </li>
                                <li>Ensure regulatory compliance with a system certified for use as an alternative to
                                    water bath test
                                </li>
                                <li>No calibration requirements or moving parts simplifies maintenance
                                </li>
                                <li>Shift from reactive to proactive maintenance practices with continuous health
                                    diagnostics
                                </li>
                                <li>Intuitive local operator interface and pre-built analytics provide actionable
                                    insights and visibility into batch quantities and rejection rates to optimize
                                    process control
                                </li>
                                <li>ATEX zone 2 and Class 1 Division 2 certified for use in hazardous environments and
                                    with explosive gases
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="content-row">
                        <div className="content-column" style={{flex: 1}}>
                        </div>
                        <div className="content-column brick-right">
                            <h2 style={{textAlign: 'left', width: '100%'}}>Specifications:</h2>
                            <p style={{textAlign: 'left', width: '100%', paddingLeft: '20px'}}>
                                <strong>Detected Gas:</strong><br/>
                                Propane, Butane, LPG, N2O, CO2, DME, R1234ze, R134a, R227 and others upon request
                                Sensitivity<br/>
                                <strong>Post water bath:</strong> 8 x 10-3 mBar l/s-1<br/>
                                <strong>Water bath alternative:</strong> 2 x 10-3mBar l/s-1<br/>
                                <strong>Laser Classification:</strong> Class 1<br/><br/>
                                <strong>Applications:</strong><br/> Propellants in food, medical, household, personal care, automotive and
                                industrial products<br/><br/>
                                <strong>Certifications/Approvals:</strong><br/>
                                <strong>Class 1 Division 2:</strong> Groups A, B, C, D, T6 (Tamb +10 to +40 °C)<br/>
                                <strong>ATEX hazardous area classification:</strong> Ex II 3G Ex nR II T6 (10 °C ≤ Tamb ≤ 40 °C)<br/><br/>
                                <strong>Enclosure Rating:</strong> IP65<br/><br/>
                                <strong>Dimensions:</strong><br/>
                                <strong>Low Speed Console (unmounted):</strong><br/> 38 cm H x 61 cm W x 22 cm D (15 in. H x 24 in. W x 9 in.
                                D)<br/>
                                <strong>Single Propellant Sensor:</strong><br/> 182 cm H x 33 cm W x 33 cm D (72 in. H x 13 in. W x 13 in.
                                D)<br/>
                                <strong>Enclosure:</strong><br/> 272 cm H x 54 cm W x 182 cm D (107 in. H x 21 in. W x 72 in. D)<br/>
                                <strong>High Speed Console:</strong><br/> 120 cm H x 60 cm W x 50 cm D (47 in. H x 24 in. W x 22 in. D)<br/>
                                <strong>Multi-Propellant Sensor:</strong><br/> 60 cm H x 33 cm W x 33 cm D (24 in. H x 13 in. W x 13 in.
                                D)<br/><br/>
                                <strong>Weight</strong><br/>
                                Console: Up to 70 kg (154 lbs.)<br/>
                                Sensor: Up to 20 kg (44 lbs.)<br/>
                                Line Space Required1.8 m (71 in.) straight free line (maximum)<br/>
                            </p>
                        </div>
                    </div>
                    <div className="content-row">
                        <div style={{width: '100%'}}>

                        </div>
                    </div>
                </div>}
        </Content>
    );
}

export default Ct2211;
