import './App.css';
import Header from "./Sections/Header";
import React, {useEffect, useRef, useState, Suspense} from "react";
import LoadingSpinner from "./LoadingSpinner";
import i18n from './i18n';
import Footer from "./Sections/Footer";
import Ct4215 from "./Sections/Ct4215";
import Contact from "./Sections/Contact";
import Ct2211 from "./Sections/Ct2211";
import About from "./Sections/About";
import Video from "./Sections/Video";
import Press from "./Sections/Press";

function App() {

    const about = useRef();
    const ct4215 = useRef();
    const ct2211 = useRef();
    const contact = useRef();
    const video = useRef();
    const press = useRef();

    const [selectedItem, setSelectedItem] = useState(0);
    const [showMenu, setShowMenu] = useState(false);

    const [locale, setLocale] = useState("en");

    const handleOnBtnClick = (id) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.querySelector("[id=" + id + "]").getBoundingClientRect().top - document.body.getBoundingClientRect().top - 90,
        })
    };

    const handleGoTo = (id) => {
        const element = document.querySelector("[id=" + id + "]");
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    100,
            })
        }
    }

    const handleScroll = () => {
        const position = window.pageYOffset;

        if (position < document.body.getBoundingClientRect().top + 800) {
            setSelectedItem(0);
            setShowMenu(false);
        } else if (position > about.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 - 150 && position < about.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 + 150) {
            setSelectedItem(1);
            setShowMenu(true);
        } else if (position > ct2211.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 - 150 && position < ct2211.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 + 150) {
            setSelectedItem(2);
            setShowMenu(true);
        } else if (position > ct4215.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 - 150 && position < ct4215.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 + 150) {
            setSelectedItem(3);
            setShowMenu(true);
        } else if (position > contact.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 - 150 && position < contact.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100 + 150) {
            setSelectedItem(4);
            setShowMenu(true);
        } else {
            setSelectedItem(-1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLang = (lang) => {
        i18n.changeLanguage(lang).then(() => {
            setLocale(lang);
        });
    }

    return (
        <div className="App">
            <Suspense fallback={<LoadingSpinner/>}>
                <Header goTo={handleGoTo} changeLang={handleLang} language={locale}
                        selectedItem={selectedItem} showMenu={showMenu}/>
                <About menuref={about}/>
                <Press menuref={press}/>
                <Video menuref={video} noheight/>
                <Ct2211 menuref={ct2211} fullWidth/>
                <Ct4215 menuref={ct4215} fullWidth/>
                <Contact menuref={contact} fullWidth/>
                <Footer/>
            </Suspense>
        </div>
    );
}

export default App;
