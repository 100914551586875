import React from 'react';
import Content from "./components/Content";
import {useTranslation} from "react-i18next";
import SentenceSlider from "./components/SentenceSlider";

function About(props) {

    const {t, i18n} = useTranslation();

    return (
        <Content background="about" width={props.fullWidth ? "full-width" : ""}
                 height={props.withFeature ? "with-feature" : ""}>
            <div id="about" ref={props.menuref}><h1>{t('menu_title_1')}</h1></div>
            <div className="content">
                <div className="content-row">
                    {i18n.language === "pl" &&
                        <div className="column-50">
                            <h2>Kim jesteśmy</h2>
                            <p style={{textAlign: 'justify'}}><strong>Elprimero Sp. z o.o.</strong> może pochwalić się /
                                posiada duże doświadczenie w branży aerozolowej, zarówno w zakresie engineeringu jak i w
                                dostawach wysokiej jakości automatycznych linii do produkcji aerozoli. Od 2022 roku jest
                                oficjalnym, autoryzowanym przedstawicielem firmy Cascade-Emerson. Początek historii
                                firmy Cascade-Emerson można datować na 22 grudnia 2014, kiedy to, firma Emerson przejęła
                                firmę Stirling oraz szkocką firmę Cascade Technologies Ltd. producenta analizatorów i
                                urządzeń do detekcji śladowych ilości gazów w oparciu o laserową technologię QCL
                                (Quantum Cascade Laser). Technologia ta, pozwala wykrywać wiele gazów jednocześnie co
                                jest niezmiernie ważne dla ograniczenia emisji gazów w warunkach przemysłowych do
                                otaczającego środowiska.
                                <br/><br/>
                                Dzięki zastosowaniu tej innowacyjnej technologii laserowej, firma Emerson poszerzyła
                                zarówno swoje możliwości w zakresie pomiarów analitycznych jak i portfolio analizatorów
                                gazów Rosemount Analytical. Technologia QCL zapewnia skokową zmianę w wydajności
                                analizatora gazu dzięki zwiększonej czułości i szybkości reakcji. Taki postęp
                                technologiczny na rynku analizatorów gazów zapewnił wydajne rozwiązanie dla klientów z
                                różnych branż, na przykład takich jak petrochemiczna, spożywcza i napojowa, morska,
                                motoryzacyjna i farmaceutyczna.<br/><br/></p>
                        </div>}
                    {i18n.language === "en" &&
                        <div className="column-50">
                            <h2>Who are we?</h2>
                            <p style={{textAlign: 'justify'}}>
                                <strong>Elprimero Sp. z o.o.</strong> is a recognized and confirmed official
                                representative of the Cascade-Emerson since 2022.
                                We have a history of many years in the business for engineering and delivery of the high
                                speed and automatic aerosol production lines.<br/>
                                Our come, in history starts in December 22, 2014 when Emerson acquired Stirling,
                                Scotland-based Cascade Technologies Ltd., a manufacturer of gas analyzers and monitoring
                                systems using Quantum Cascade Laser (QCL) technology. This innovative technology
                                measures multiple gases simultaneously, helping companies improve industrial emissions
                                monitoring, production efficiencies and environmental compliance.<br/><br/>
                                Emerson is expanding its analytical measurement capabilities by adding this innovative
                                laser technology to its Rosemount Analytical gas analysis portfolio. QCL technology
                                provides a step change in gas analyzer performance through its increased sensitivity,
                                speed of response, and fingerprinting capability. These technology advancements in the
                                gas analysis market space provide a powerful solution for customers in various
                                industries such as petrochemical, food and beverage, marine, automotive and
                                pharmaceutical.<br/><br/>
                            </p></div>}
                    <div className="column-50-stretch">
                        <div className="image" style={{
                            marginTop: "20px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <img src="elprimero2.jpg" style={{width: '100%'}}/>
                        </div>
                    </div>
                </div>
                <div className="content-row">
                    <div className="column-25-stretch">
                        <div className="image" style={{
                            marginTop: "20px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <img src="emerson.png" style={{width: '100%'}}/>
                        </div>
                    </div>
                    {i18n.language === "pl" &&
                        <div className="column-75">
                            <p style={{textAlign: 'justify'}}>Warunków przejęcia nie ujawniono. Jak stwierdził tom
                                Moser, wiceprezes grupy ds. pomiarów i analiz w Emerson Process Managemet - „Przejęcie
                                Cascade Technologies to ekscytujący krok, ponieważ jeszcze bardziej wzmacniamy naszą
                                ofertę analiz gazów” i dalej „Nasi klienci polegają na firmie Emerson w rozwiązywaniu
                                najtrudniejszych problemów związanych z pomiarami analitycznymi. Jesteśmy teraz w
                                lepszej sytuacji, aby zaspokoić te potrzeby”. Dr Iain Howieson, dyrektor generalny
                                Cascade Technologies Ltd., dodał: „Dołączenie do światowego lidera, jakim jak Emerson,
                                stanowi niesamowitą szansę na rozwój biznesu. Globalna obecność firmy Emerson i wiodąca
                                pozycja na rynku będą miały natychmiastowy wpływ na przyjęcie najnowocześniejszych
                                analizatorów gazów i systemów monitorowania QCL”.
                                <br/><br/>Siedziba firmy Emerson znajduje się w St. Louis w stanie Missouri (USA). Firma
                                Emerson dzięki umiejętności łączenia technologii i inżynierii jest światowym liderem
                                dostarczającym innowacyjne rozwiązań dla klientów na rynkach przemysłowych, handlowych i
                                konsumenckich, na całym świecie. Działalność firmy opiera się na następujących pięciu
                                sektorach biznesowych: - zarządzanie procesami; - automatyka przemysłowa; - zasilanie
                                sieciowe; - technologie klimatyczne; - rozwiązania komercyjne i mieszkaniowe. Sprzedaż w
                                roku finansowym 2014 zamknęła się kwotą w wysokości 24,5 mld USD. Więcej informacji
                                znajdziecie na stronie www.emerson.com. Sektor Zarządzanie procesami (Process
                                Management) jest liderem w dziedzinie świadczenia pomocy firmom w zakresie automatyzacji
                                produkcji, przetwarzania i dystrybucji w przemyśle chemicznym, naftowym i gazowym,
                                rafineryjnym, celulozowo-papierniczym, energetycznym, wodno-ściekowym, górnictwie i
                                przemyśle metalowym, produkcji żywności i napojowymi innych gałęziach przemysłu. Firma
                                łączy doskonałe produkty i technologię z inżynierią dostosowaną do branży, doradztwo,
                                zarządzanie projektami i usługi serwisowe. Jej marki obejmują PlantWeb, Syncade, DeltaV,
                                Fisher, Bettis, Micro Motion, Rosemount, Daniel, Ovation i AMS Suite.</p>
                        </div>}
                    {i18n.language === "en" &&
                        <div className="column-75">
                            <p style={{textAlign: 'justify'}}>Terms of the acquisition were not disclosed. “The
                                acquisition of Cascade Technologies is an exciting step as we further strengthen our gas
                                analysis portfolio,” said Tom Moser, group vice president of Emerson Process
                                Management’s measurement and analytical businesses. “Our customers depend upon Emerson
                                to solve their toughest analytical measurement problems. We are now better positioned to
                                serve that need.” Dr. Iain Howieson, chief executive officer of Cascade Technologies
                                Ltd., added, “Joining a global leader like Emerson represents an incredible opportunity
                                for business growth. Emerson’s global presence and market leadership will have an
                                immediate impact on the adoption of cutting edge QCL gas analyzers and monitoring
                                systems.” <br/><br/>Emerson, based in St. Louis, Missouri (USA), is a global leader
                                in bringing technology and engineering together to provide innovative solutions for
                                customers in industrial, commercial, and consumer markets around the world. The company
                                is comprised of five business segments: Process Management, Industrial Automation,
                                Network Power, Climate Technologies, and Commercial & Residential Solutions. Sales in
                                fiscal 2014 were $24.5 billion. For more information, visit Emerson.com. About Emerson
                                Process Management Emerson Process Management, an Emerson business, is a leader in
                                helping businesses automate their production, processing and distribution in the
                                chemical, oil and gas, refining, pulp and paper, power, water and wastewater treatment,
                                mining and metals, food and beverage, life sciences and other industries. The company
                                combines superior products and technology with industry-specific engineering,
                                consulting, project management and maintenance services. Its brands include PlantWeb,
                                Syncade, DeltaV, Fisher, Bettis, Micro Motion, Rosemount, Daniel, Ovation and AMS
                                Suite.</p></div>}
                </div>
                <div className="content-row">
                    <div style={{width: '100%'}}>
                        {i18n.language === "pl" && <SentenceSlider
                            sentences={["Wieloletnie doświadczenie w branży", "Lider sprzedaży wymienników ciepła", "Szybki serwis i wzorowa obsługa gwarancyjna"]}/>}
                        {i18n.language === "en" && <SentenceSlider
                            sentences={["Many years of experience in the industry", "Leader in the sale of heat exchangers", "Fast service and exemplary warranty service"]}/>}
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default About;
